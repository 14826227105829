.mat-mdc-slide-toggle {

  --mdc-switch-disabled-selected-handle-color: #FFFF;
  --mdc-switch-disabled-selected-track-color: black;
  --mdc-switch-disabled-unselected-handle-color: #FFFF;
  --mdc-switch-disabled-unselected-track-color: black;

  --mdc-switch-handle-surface-color: #FFFF;
  --mdc-switch-handle-shadow-color: #FFFF;

  --mdc-switch-unselected-focus-handle-color: #FFFF;
  --mdc-switch-unselected-focus-track-color: black;
  --mdc-switch-unselected-pressed-handle-color: #FFFF;
  --mdc-switch-unselected-pressed-track-color: black;

  --mdc-switch-unselected-hover-track-color: black;
  --mdc-switch-unselected-hover-handle-color: #FFFF;
  --mdc-switch-unselected-handle-color: #FFFF;
  --mdc-switch-unselected-track-color: black;
}

.mat-mdc-slide-toggle .mdc-form-field {
  color: #FFFFFF;
}

.mat-mdc-slide-toggle {
  --mat-slide-toggle-label-text-size: 18px !important;
  --mat-slide-toggle-label-text-line-height: 24px !important;
  --mat-slide-toggle-label-text-weight: 500 !important;
}
