@import 'globals';

.ct-series-a .ct-line,
.ct-series-a .ct-point {
  stroke: $primary;
}

.ct-series-a .ct-area {
  fill: $primary;
}
