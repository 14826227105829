$primary: #D74158;
$green: #3BC458;
$warn: #ED323A;
$yellow: #DEC24D;

$text-white: #FFFFFF;
$text-black: #1F1F1F;
$text-grey: #8F908F;

$vvcr-text-black: #252C32;
$vvcr-text-black-2: #282525;
$vvcr-text-grey: #B5B5B5;
$vvcr-grey: #A7A7AE;

$bg-black: #1F1F1F;
$bg-grey: #F5F6FA;
$bg-grey-hover: #424242;
$bg-grey-active: #4F4F4F;

$bg-light-grey-hover: #f1f1f1;
$bg-light-grey-active: #ebebeb;
$bg-card-grey: #F9F9F9;
