@import 'globals';

html, body { height: 100%; }
body { margin: 0; font-family: 'Work Sans', sans-serif !important; }

.green {
  color: $green !important;
}

.text-grey {
  color: $text-grey;
}

.inline {
  display: inline-block !important;
}

.block {
  display: block !important;
}

.title-block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.notify-error {
  .mat-mdc-snack-bar-action {
    color: $warn !important;
  }
}

.copy-link {
  .mat-mdc-snack-bar-action {
    color: $primary !important;
  }
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vvcr-grey {
  color: $vvcr-grey !important;
}

.primary {
  color: $primary !important;
}

h4.title {
  font-weight: normal !important;
}

/* MapBox package styles */
.mapboxgl-popup-content {
  padding: 0 !important;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: #303941 !important;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
  border-bottom-color: #303941 !important;
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: #303941 !important;
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: #303941 !important;
}

.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip {
  border-bottom-color: #303941 !important;
}

.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: #303941 !important;
}

.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip {
  border-top-color: #303941 !important;
}

.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: #303941 !important;
}

/* Collabora package style */
collabora .collabora-container .collabsdk__users-list .collabsdk__user .collabsdk__video-content {
  margin-left: 6px;

  .collabsdk__video-disabled {
    padding: 0 !important;
  }
}

app-collabora collabora-sidebar .collabora-sidebar-container {
  .collabsdk__controls {
    margin-top: 25px;

    .collabsdk__tooltip{
      z-index: 3;
    }

    &__settings {
      transform: translate(-50%, 56%) !important;
    }
  }

  .collabsdk__users-list {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    .collabsdk__video-disabled {
      padding: 0 !important;
    }
  }
}
