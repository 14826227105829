@import 'globals';

.sidenav-accordion .mat-expansion-panel-body {
  padding: 0 !important;

  mat-list-item {
    padding-left: 16px;
  }
}

.sidenav-accordion mat-expansion-panel {
  box-shadow: none !important;
}

.sidenav-accordion .mat-expansion-panel-header-description, .mat-expansion-indicator::after {
  color: $text-black !important;
}

@media (max-width: 599px) {
  .header .mat-toolbar-row, .mat-toolbar-single-row {
    height: 64px !important;
  }
}

.mat-focused .mat-form-field-label {
  color: $primary !important;
}

.mat-form-field-ripple {
  background-color: $primary !important;
}

.mat-checkbox-layout {
  white-space: normal !important;
}

.mat-radio-label-content {
  white-space: normal !important;
}

.menege-cluster-form-block-3 .mat-checkbox-inner-container {
  margin-top: 5px;
}

.mat-expansion-panel {
  & .mat-expansion-panel-header.cdk-keyboard-focused,
  & .mat-expansion-panel-header.cdk-program-focused,
  &:not(.mat-expanded) .mat-expansion-panel-header:hover {
    &:not([aria-disabled='true']) {
      background: $bg-black !important;
    }
  }
}

.ng-trigger.ng-trigger-detailExpand {
  width: 100% !important;
}

.vu-toggle .mat-slide-toggle-content {
  font-size: 16px !important;
}

.external-org {
  position: relative !important;
  right: 30px !important;
  width: 65px !important;
}

.entity-toggle-btn .mat-button-toggle-label-content {
  line-height: 30px !important;
}

.entity-toggle-btn .mat-button-toggle-checked{
  background-color: $primary !important;

  .mat-button-toggle-label-content{
    color: $text-white !important;
  }
}

.vvcr-select {
  background: #FFFFFF;
  border: 1px solid #DDE2E4;
  box-sizing: border-box;
  border-radius: 6px;
  height: 40px;
  padding-top: 5px;

  .mat-mdc-select-value-text {
    margin-left: 30px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.006em;
    color: $vvcr-text-black;
  }
}

.vvcr-menu {
  .mat-tab-link {
    min-width: 80px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.006em;
    opacity: 1;
    color: $vvcr-text-grey;
  }

  .mat-tab-label-active {
    color: $primary;
  }
}

.monitoring-menu {
  .mat-tab-label {
    min-width: 80px !important;
  }
}

.vvcr-bandwith-chart {
  .highcharts-title {
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 12px !important;
    line-height: 120% !important;
    fill: #000000 !important;
  }
}

.highcharts-legend,
.highcharts-credits {
  display: none;
}

.mv-password {
  .mat-form-field-infix {
    margin-top: -5px !important;
  }
}
