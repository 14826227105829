.app-form {
  width: 100%;

  mat-card {
    padding: 40px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;

    .mat-mdc-card-content {
      padding-left: 0;
    }

    .mat-mdc-card-content.horizontal {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }

  .form-block {
    width: 350px;
  }

  mat-card-title {
    margin-left: -16px;
  }

  mat-form-field {
    width: 100%;
    display: block;
    margin: 10px 0;
  }

  mat-radio-button {
    display: block;
  }

  .mat-radio-label {
    white-space: normal;
  }

  mat-card-actions {
    width: 100%;
    text-align: right;
  }

  mat-checkbox {
    white-space: normal;
    display: block;
  }

  mat-card-actions {
    button {
      width: 100px;
      margin-left: 20px;
    }
  }

  @media screen and (max-width: 1480px) {
    .form-block {
      width: 400px;
    }
  }

  @media screen and (max-width: 1220px) {
    .form-block {
      width: 300px;
    }
  }

  @media screen and (max-width: 1020px) {
    .form-block {
      width: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    mat-card-actions {
      text-align: center;
      margin: 0;

      button {
        margin-left: 10px;
      }
    }
  }

  @media screen and (max-width: 480px) {
    mat-card {
      padding: 25px 20px;
    }
  }
}
