html {
  --mdc-filled-text-field-container-color: none;
  --mdc-filled-text-field-disabled-container-color: none;
  --mat-form-field-focus-state-layer-opacity: 0;
  --mat-form-field-hover-state-layer-opacity: 0;
}

.mat-mdc-form-field-infix {
  min-height: 0 !important;
}

.mdc-text-field {
  padding: 0 !important;
}

.mat-mdc-form-field-error-wrapper {
  padding: 0 !important;
}

.mat-mdc-form-field-subscript-wrapper {
  font-size: 70% !important;
  line-height: 1.125 !important;
}

.protocol {
  .mat-mdc-select-arrow-wrapper {
    padding-right: 10px;
  }
  .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
    padding-top: 12px;
  }
}

/* Angular 16 styles */
.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0 !important;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0 !important;
}
