html {
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.87)
}

.mat-mdc-dialog-surface {
  padding: 24px !important;
}

.mat-mdc-dialog-title {
  padding: 0 !important;
  margin-bottom: 16px !important;
}

.mat-mdc-dialog-content {
  padding: 0 !important;
}

.mat-mdc-dialog-actions {
  padding-top: 6px !important;
  padding-bottom: 0 !important;
  padding-right: 0 !important;
  padding-left: 0 !important;

  button {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .mat-mdc-button-base+.mat-mdc-button-base {
    margin-left: 8px;
  }
}
