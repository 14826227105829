.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 0;
}

html {
  --mdc-checkbox-state-layer-size: 26px;
}

.mdc-checkbox {
  flex: 0 0 16px !important;
  width: 16px !important;
  height: 16px !important;
}

html {
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.87) ;
}

.mat-mdc-dialog-surface {
  padding: 24px !important;
}

.mat-mdc-dialog-title {
  padding: 0 !important;
  margin-bottom: 16px !important;
}

.mat-mdc-dialog-content {
  padding: 0 !important;
}

.mat-mdc-dialog-actions {
  padding-top: 6px !important;
  padding-bottom: 0 !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.mat-mdc-dialog-actions button {
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.mat-mdc-dialog-actions .mat-mdc-button-base + .mat-mdc-button-base {
  margin-left: 8px;
}

html {
  --mdc-filled-text-field-container-color: none;
  --mdc-filled-text-field-disabled-container-color: none;
  --mat-form-field-focus-state-layer-opacity: 0;
  --mat-form-field-hover-state-layer-opacity: 0;
}

.mat-mdc-form-field-infix {
  min-height: 0 !important;
}

.mdc-text-field {
  padding: 0 !important;
}

.mat-mdc-form-field-error-wrapper {
  padding: 0 !important;
}

.mat-mdc-form-field-subscript-wrapper {
  font-size: 70% !important;
  line-height: 1.125 !important;
}

.protocol .mat-mdc-select-arrow-wrapper {
  padding-right: 10px;
}
.protocol .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 12px;
}

/* Angular 16 styles */
.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0 !important;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0 !important;
}

.mdc-list-item:focus::before {
  background-color: transparent !important;
}

.mat-mdc-menu-panel {
  max-width: none !important;
}

.mdc-radio {
  padding-left: 0 !important;
}
.mdc-radio .mat-ripple-element {
  display: none !important;
}

.mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #FFFF;
  --mdc-switch-disabled-selected-track-color: black;
  --mdc-switch-disabled-unselected-handle-color: #FFFF;
  --mdc-switch-disabled-unselected-track-color: black;
  --mdc-switch-handle-surface-color: #FFFF;
  --mdc-switch-handle-shadow-color: #FFFF;
  --mdc-switch-unselected-focus-handle-color: #FFFF;
  --mdc-switch-unselected-focus-track-color: black;
  --mdc-switch-unselected-pressed-handle-color: #FFFF;
  --mdc-switch-unselected-pressed-track-color: black;
  --mdc-switch-unselected-hover-track-color: black;
  --mdc-switch-unselected-hover-handle-color: #FFFF;
  --mdc-switch-unselected-handle-color: #FFFF;
  --mdc-switch-unselected-track-color: black;
}

.mat-mdc-slide-toggle .mdc-form-field {
  color: #FFFFFF;
}

.mat-mdc-slide-toggle {
  --mat-slide-toggle-label-text-size: 18px !important;
  --mat-slide-toggle-label-text-line-height: 24px !important;
  --mat-slide-toggle-label-text-weight: 500 !important;
}

/* Header */
html {
  --mat-table-header-headline-line-height: 20px;
  --mat-table-header-headline-size: 12px;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.54);
}

/* Paginator */
html {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.54);
}

.mdc-tooltip__surface {
  white-space: pre-line;
  max-width: unset !important;
  line-height: 22px !important;
}