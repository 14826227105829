.app-trimming-player {
  .vjs-control-bar {
    height: 140px !important;
    left: 0px !important;
    background: #2f3031 !important;
    top: 100%;
  }

  .vjs-progress-control {
    position: absolute;
    height: 3em !important;
    top: 33px !important;
  }
  .vjs-progress-holder {
    width: 1824px;
    height: 36px !important;
    background: #434343;
    opacity: 0.6;
    border-radius: 2px;
  }

  .video-js .vjs-play-progress {
    background: none !important;
  }

  .video-js .vjs-play-progress:before {
    transition: width 0.1s ease-out, height 0.1s ease-out !important;
    content: "" !important;
    top: 0em !important;
    background: radial-gradient(#b7090b 33%, #830607) !important;
    width: 3px !important;
    height: 36.13px !important;
    opacity: 0.5 !important;
    border: 2px solid #ffffff !important;
    box-sizing: border-box !important;
    border-radius: 2px !important;
  }

  .vjs-marker.marker-start,
  .vjs-marker.marker-end {
    border: 2px solid #689acf;
    padding: 2px;
    border-radius: 4px;
    width: 10px;
    transform: scale(1.3, 1.3);
    background: #ffffff !important;
  }

  .vjs-marker.marker-thumb {
    width: 4px;
    border: 2px solid red;
    box-sizing: border-box;
    border-radius: 2px;
  }
  .vjs-marker.marker-range {
    background: #EA0029 !important;
    border-radius: none !important;
    z-index: 0 !important;
    pointer-events: none;
  }
  .vjs-marker.marker-range:hover {
    transform: none !important;
  }

  .vjs-play-control,
  .vjs-progress-control {
    font-size: 15px !important;
    top: 19px;
    height: 15.13px !important;
  }

  .vjs-play-control:focus {
    outline: 0;
  }

  .vjs-volume-panel {
    position: absolute !important;
    top: 81px !important;
    height: 5% !important;
  }

  .revind-back-sec {
    cursor: pointer !important;
  }

  .custom-controls {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    bottom: 0;
    height: 43%;
    left: 11%;
    width: 88%;
  }

  .video-ctr-custom-button {
    background: #3f4042 !important;
    font-style: normal !important;
    font-weight: 600;
    font-size: 1.5em !important;
    line-height: 22px !important;
    height: 73% !important;
    border-radius: 8px !important;
  }

  .vjs-quality-selector {
    position: absolute !important;
    right: 4%;
    bottom: 0;
    height: 4em !important;
    font-style: normal !important;
    font-weight: 600;
    font-size: 1.5em !important;
  }

  .vjs-control.vjs-progress-control {
    height: 3em;
    position: absolute !important;
    width: 96% !important;
    left: 4% !important;
  }

  .material-icons {
    vertical-align: middle;
  }

  a.video-ctr-custom-button {
    background: none !important;
    cursor: pointer;
    margin-left: 2%;
    padding: 3px;
  }
  a.video-ctr-custom-button:hover {
    color: #689acf;
  }
  button.video-ctr-custom-button:hover {
    background: #55585b !important;
  }

  a.video-ctr-custom-button:active {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  }

  .vjs-volume-control {
    position: absolute !important;
    bottom: 0;
    height: 37% !important;
    width: 9em !important;
    left: 2em;
  }

  .vjs-volume-level {
    background-color: #689acf !important;
  }
  .vjs-volume-bar {
    width: 100% !important;
  }

  .vjs-time-control {
    position: absolute !important;
    right: 0;
    bottom: 0;
    height: 4em !important;
    font-style: normal !important;
    font-weight: 600;
    font-size: 1.5em !important;
  }

  .vjs-current-time {
    display: block !important;
  }

  .video-element figure {
    align-items: unset !important;
    justify-content: end !important;
  }

  .video-dimensions {
    height: 70% !important;
    width: auto;
  }

  .video-js .vjs-load-progress div {
    background: none !important;
  }

  .video-js .vjs-load-progress {
    background: none !important;
  }

  .trim-info figure {
    align-items: unset !important;
  }

  .info-words figure {
    align-items: center !important;
  }

  .info-words figure input {
    width: 100% !important;
  }

  .review-button figure {
    justify-content: flex-end !important;
    align-items: end !important;
  }

  .video-js .vjs-progress-control:hover .vjs-progress-holder {
    font-size: 1.6666666667em;
  }

  //

  .app-select-segment-video .video-js {
    background-color: transparent;
    height: 100% !important;
  }

  .app-select-segment-video .vjs-play-control {
    top: 8px;
  }

  .app-select-segment-video .vjs-volume-level {
    background-color: #EA0029 !important;
  }

  .app-select-segment-video .vjs-volume-level:before {
    color: #EA0029 !important;
  }

  .app-select-segment-video .vjs-control-bar {
    width: 100%;
    min-width: 800px;
    height: 102px !important;
    background-color: #212121 !important;
  }

  .app-select-segment-video .vjs-progress-holder {
    height: 24px !important;
    width: 662px;
    border-radius: 2px;
  }

  .app-select-segment-video .vjs-progress-control {
    width: 80% !important;
    left: 18% !important;
    top: 22px !important;
  }

  .app-select-segment-video .vjs-progress-control .vjs-progress-holder {
    background-color: #373737;
  }

  .app-select-segment-video .vjs-volume-control {
    bottom: 49px;
    left: 5em;
  }

  .app-select-segment-video .vjs-time-control {
    right: auto;
    left: 8px;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
    height: auto !important;
    bottom: 10px !important;
  }

  .app-select-segment-video .vjs-quality-selector {
    right: auto;
    left: 0;
    bottom: -7px;
  }

  .app-select-segment-video .custom-controls {
    a {
      font-style: normal !important;
      font-weight: bold !important;
      font-size: 12px !important;
    }
  }

  .app-select-segment-video .vjs-play-progress:before {
    height: 25.13px !important;
    border: 2px solid #FFFFFF !important;
  }

  .rewind-block {
    position: relative;
    left: 135px;
    top: -9px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .actions-block {
    position: absolute;
    right: 18px;
    bottom: 16px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
    }
  }

  .player-action-btn {
    width: 30px;
    height: 30px;
    background-color: #373737;
    border-radius: 4px;
    cursor: pointer;

    a {
      background-repeat: no-repeat !important;
      width: 15px !important;
      height: 15px !important;
      display: block;
    }
  }

  .rewind-to-start {
    a {
      background-image: url(./../assets/player-icons/remind-start-icon.svg) !important;
      width: 25px !important;
    }
  }

  .start {
    a {
      background-image: url(./../assets/player-icons/clip-start-icon.svg) !important;
    }
  }

  .thumbnail {
    a {
      background-image: url(./../assets/player-icons/thumbnail-icon.svg) !important;
    }
  }

  .end {
    a {
      background-image: url(./../assets/player-icons/clip-end-icon.svg) !important;
    }
  }

  .rewind-to-end {
    a {
      background-image: url(./../assets/player-icons/remind-end-icon.svg) !important;
      width: 25px !important;
    }
  }

  .mr {
    margin-right: 8px;
  }

  .ml {
    margin-left: 8px;
  }

  .app-select-segment-video .vjs-menu {
    top: 30px;
    left: 5px;
    z-index: 9;

    li {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      color: #FFFFFF;
      background-color: #002A3A;
    }

    li:hover {
      opacity: 0.8;
      background-color: #002A3A !important;
    }

    .vjs-menu-title {
      font-weight: normal;
      margin: 0;
    }

    .vjs-selected {
      background-color: #FFFFFF;
      color: #002A3A;
    }

    .vjs-selected:hover {
      background-color: #FFFFFF !important;
      opacity: 0.8;
    }
  }

  .app-select-segment-video .vjs-mouse-display {
    width: 2px !important;
    background-color: #FFFFFF !important;
  }
}
