

.app-table {
  .actions {
    button {
      display: block;
    }
  }

  @media screen and (max-width: 1024px) {
    .mat-mdc-table {
      border: 0;
      vertical-align: middle
    }

    .mat-mdc-table caption {
      font-size: 1em;
    }

    .mat-header-row {
      display: none;
    }

    .mat-mdc-table .mat-mdc-row {
      border-bottom: 5px solid #ddd;
      display: block;
      font-weight: bold;
    }

    .mat-mdc-table .mat-mdc-cell {
      border-bottom: 1px solid #ddd;
      font-size: 1em;
      text-align: right;
      display: flex;
      justify-content: space-between;
      padding: 10px;
      align-items: center;
    }

    .mat-mdc-table .mat-mdc-cell:before {
      content: attr(data-label);
      float: left;
      font-weight: normal;
      font-size: .85em;
    }

    .mat-mdc-table .mat-mdc-cell:last-child {
      border-bottom: 0;
    }

    .mat-mdc-table .mat-mdc-cell:first-child {
      margin-top: 4%;
    }

    mat-row::after, mat-header-row::after, mat-footer-row::after {
      display: inline-block;
      min-height: auto;
      content: none;
    }

    .actions {
      button {
        display: inline-block;
      }
    }
  }
}

.mat-column-status {
  text-align: center !important;
  justify-content: center !important;
}
