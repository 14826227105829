/* Header */
html {
  --mat-table-header-headline-line-height: 20px;
  --mat-table-header-headline-size: 12px;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.54);
}

/* Paginator */
html {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.54);
}
